<template>
    <div>
        <b-card>
            <b-card-body>
                <b-row>
                    <b-col md="2">
                        <label for="search">Search :</label>
                    </b-col>
                    <b-col md="9">
                        <b-input id="search" v-model="email" placeholder="email"></b-input>
                    </b-col>
                    <b-col md="1">
                        <b-button @click="search" variant="primary">
                            <b-icon icon="search"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
  
      <b-card-header> Filter </b-card-header>
      
      <b-card-body>
        <b-row>
          <b-col>
            <label for="datepicker-full-width">From</label>
            <b-form-datepicker
              id="datepicker-full-width"
              v-model="fromDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label for="datepicker-full">To</label>
            <b-form-datepicker
              id="datepicker-full"
              v-model="toDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <div class="text-center">
            <b-button @click="filterByDate" variant="primery">filter</b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-button @click="downloadFile()">export csv</b-button>
        <div id="printMe">
          <b-table  striped hover :items="items"></b-table>
        
 
        </div><b-button class="float-right" variant="primary" @click="print()">Print</b-button> 
        
        
         <b-pagination
         
        @input="pageChange(currentPage)"
      v-model="currentPage"
      v-if="!filterdData"
      align="center"
      :total-rows="rows"
      :per-page="perPage"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
    
    
    </div>
</template>
<script>
const {getFields,getFieldsByOneProperty,getPerPage,getFieldsPage, getDataFilterByDate} = require("../../assets/js/service")
import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import exportFromJSON from "export-from-json";
const options = {
  name: '_blank',
  specs: [
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);
export default {
    data() {
        return {
            email:null,
            items:[],
             currentPage:1,
            rows:1,
            perPage:1,
            filterdData:false
        }
    },
    methods: {
        pageChange(currentPage){
      let token = localStorage.getItem('token');
      getFieldsPage('Subscribtions',currentPage,token).then(resp=>{
          
 this.items = resp.data.map(function(item) { 
    delete item.id; 
    item.date = Date(item.date)
    return item; 
});;
      })
    },
        search(){
            
            if(this.email){
                let token = localStorage.getItem('token');
                getFieldsByOneProperty('Subscribtions','email',this.email,token).then(resp=>{
                    this.filterdData =true;
                    this.items=resp.data.map(function(item) { 
    delete item.id; 
    item.date = Date(item.date)
    return item; 
});;
                })
            }
        },
        print () {
      
      this.$htmlToPaper('printMe');
    }
    },
    mounted() {
         let token = localStorage.getItem('token');
    getFields('Subscribtions/count',token).then(resp=>{

      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage('Subscribtions',1,token).then(resp=>{
//  this.items = resp.data.map(function(item) { 
//     delete item.id; 
//     item.date = Date(item.date)
//     return item; 
// });

 this.items = resp.data.map(item=>{

     let singleItem = {
         email:null,
         date:null,
     };
     singleItem.email = item.email;
      const options = { year: "numeric", month: "long", day: "numeric" }
    
     singleItem.date = new Date(item.date).toLocaleDateString(undefined, options);
     
     return  singleItem
 });
      })
    })
       
    },
    methods: {
         downloadFile() {
      const data = this.items;
      const fileName = "Subscribtions";
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });
    },
          filterByDate() {
      this.filterdData = true;
      let token = localStorage.getItem("token");
      getDataFilterByDate(
        "Subscribtions",this.toDate,this.fromDate,
        token
      ).then((resp) => {
        console.log(resp);
        this.items = resp.data;
      });
    },
    },

}
</script>
<style>

</style>